import useTranslation from '@/utils/i18n/useTranslation';
import { assertNever } from '@/utils/types/types';
import { BackgroundProps, ColorProps, Text } from '@chakra-ui/react';
import { TFunction } from 'i18next';

type InputTagType = 'required';

export type InputTagProps =
  | {
      type: InputTagType;
    }
  | {
      label: string;
      backgroundColor: BackgroundProps['backgroundColor'];
      color: ColorProps['color'];
    };

const InputTag = (props: InputTagProps) => {
  const { t } = useTranslation();
  const { label, backgroundColor, color } = getInputTagSettings({ props, t });

  return (
    <Text
      color={color}
      fontSize='xs'
      backgroundColor={backgroundColor}
      width='fit-content'
      height='fit-content'
      px={1}
      py={0.5}
      borderRadius='sm'
      lineHeight='short'
      fontWeight='normal'
    >
      {label}
    </Text>
  );
};

type GetInputTagSettings = {
  props: InputTagProps;
  t: TFunction<string[], undefined>;
};

const getInputTagSettings = ({ props, t }: GetInputTagSettings) => {
  if (!('type' in props)) return props;
  switch (props.type) {
    case 'required':
      return {
        label: t('form.badge.required'),
        backgroundColor: 'red.500',
        color: 'neutral.0',
      };
    default:
      assertNever(props.type);
  }
};

export default InputTag;
